import { Box, makeStyles } from "@material-ui/core";
import React from "react";
import CompletedCard from "./components/CompletedCard";
import {
  AssetIcon,
  DebtIcon,
  ExpenseIcon,
  IncomeIcon,
  LoanQuizIcon,
  PersonIcon,
} from "../../../svgs/loan-application";

const cardData = [
  {
    value: "about-you",
    label: "About You",
    description: "Basic information about you and any co-applicant.",
    icon: <PersonIcon width={24} height={24} />,
  },
  {
    value: "assets",
    label: "Assets",
    description: "A list of you current assets.",
    icon: <AssetIcon />,
  },
  {
    value: "income",
    label: "Income",
    description: "Information about your income.",
    icon: <IncomeIcon />,
  },
  {
    value: "debts",
    label: "Debts",
    description: "Your debts and liabilities.",
    icon: <DebtIcon />,
  },
  {
    value: "expenses",
    label: "Expenses",
    description: "All your expenses and spending.",
    icon: <ExpenseIcon />,
  },
  {
    value: "the-loan",
    label: "Loan Quiz",
    description: "Questions about the loan details and credit history.",
    icon: <LoanQuizIcon />,
  },
];

const useStyles = makeStyles((theme) => ({
  container: {
    width: "65%",
    display: "grid",
    gridTemplateColumns: "repeat(2, 1fr)",
    gridColumnGap: "24px",
    gridRowGap: "16px",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      gridTemplateColumns: "1fr",
      gridRowGap: "24px",
    },
  },
}));

const CompletedStep = ({ completedItems, onEditStep }) => {
  const classes = useStyles();
  const renderedItems = cardData.filter((item) =>
    completedItems.includes(item.value)
  );

  return (
    <Box classes={{ root: classes.container }}>
      {renderedItems.map((item, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <CompletedCard key={index} data={item} onEdit={onEditStep} />
      ))}
    </Box>
  );
};

export default CompletedStep;
