import {
  Box,
  Container,
  Grid,
  Tab,
  Typography,
  useMediaQuery,
} from "@material-ui/core";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import TabPanel from "@material-ui/lab/TabPanel";
import TabList from "@material-ui/lab/TabList";
import TabContext from "@material-ui/lab/TabContext";
import { useFormik } from "formik";
import { SnackbarProvider } from "notistack";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import cx from "classnames";

import { useMutation, useQueryClient } from "react-query";
import { navigate } from "gatsby";
import ReplayIcon from "@material-ui/icons/Replay";
import {
  useStyles,
  useCommonStyles,
} from "../../utils/loan-application.styles";
import AboutYou from "./about-you";
import Income from "./income";
import Expenses from "./expenses";
import Assets from "./assets";
import Debts from "./debts";
import {
  initialFormValues,
  mapFormValueToParams,
  validationSchema,
} from "../../utils/loan-application.utils";
import CompletedStep from "./completedStep";
import TheLoan from "./the-loan";
import SupportingDocuments from "./supporting-documents";
import LoanApplicationContext from "../../context/loan-application-context";
import Button from "./components/Button";
import {
  deleteMyQuickLoan,
  syncQuickLoanStates,
  updateDraftQuickLoan,
} from "../../api/scenario";
import { useNotification } from "../../hooks/loan-application.hooks";
import { useGetMyQuickLoan } from "../../hooks/scenario.hooks";
import { LoadingBackdrop } from "../../components/loading-backdrop";
import CustomModal from "../../components/CustomModal/index.tsx";

const tabs = [
  {
    key: "about-you",
    title: "About You",
    visible: true,
    component: AboutYou,
  },
  {
    key: "income",
    title: "Income",
    visible: true,
    component: Income,
  },
  {
    key: "expenses",
    title: "Expenses",
    visible: true,
    component: Expenses,
  },
  {
    key: "assets",
    title: "Assets",
    visible: true,
    component: Assets,
  },
  {
    key: "debts",
    title: "Debts",
    visible: true,
    component: Debts,
  },
  {
    key: "the-loan",
    title: "The Loan",
    visible: true,
    component: TheLoan,
  },
  {
    key: "supporting-documents",
    title: "Supporting Documents",
    visible: true,
    component: SupportingDocuments,
  },
];

const LoanApplication = () => {
  const classes = useStyles();
  const commonClasses = useCommonStyles();
  const { showNotification } = useNotification();

  const [selectedTab, setSelectedTab] = useState("about-you");
  const [editingTabs, setEditingTabs] = useState([]);
  const [isMobileFirstStep, setIsMobileFirstStep] = useState(true);
  const [assetFinishedMap, setAssetFinishedMap] = useState({});
  const [debtFinishedMap, setDebtFinishedMap] = useState({});
  const [emailsSent, setEmailsSent] = useState(new Set());
  const [currentAboutYouStepKey, setCurrentAboutYouStepKey] = useState(null);
  const isMobile = useMediaQuery("(max-width:599px)");
  const [isSubmitSuccessDialogOpen, setIsSubmitSuccessDialogOpen] = useState(
    false
  );
  const queryClient = useQueryClient();

  useEffect(() => {
    queryClient.invalidateQueries("isLoggedIn");
    queryClient.invalidateQueries();
  }, []);

  const formik = useFormik({
    initialValues: initialFormValues,
    validationSchema,
    onSubmit: () => {},
  });

  const { isFetching: isFetchingMyQuickLoan } = useGetMyQuickLoan({
    onError: () => {
      showNotification("Failed to connect to server", "error");
      navigate("/404");
    },
    onSuccess: async (response) => {
      if (!response) {
        return;
      }
      await formik.setFieldValue("scenarioId", response.id);
      await formik.setFieldValue("opportunityId", response.opportunityId);

      const loanStates = response?.scenarioObject?.loanStates;
      if (!loanStates) {
        return;
      }
      if (loanStates.rawValues) {
        await formik.setValues(loanStates.rawValues);
      }
      setSelectedTab(loanStates.selectedTab);
      setEditingTabs(loanStates.editingTabs);
      setIsMobileFirstStep(loanStates.isMobileFirstStep);
      setAssetFinishedMap(loanStates.assetFinishedMap);
      setDebtFinishedMap(loanStates.debtFinishedMap);
      setEmailsSent(new Set(loanStates.emailsSent));
      setCurrentAboutYouStepKey(loanStates.currentAboutYouStepKey);
    },
  });

  const deleteDraftQuickLoan = useMutation(deleteMyQuickLoan);
  const resetQuickLoan = useCallback(() => {
    deleteDraftQuickLoan.mutate(undefined, {
      // in case the loan is reset before user log in
      onSettled: () => {
        formik.resetForm();
        setSelectedTab("about-you");
        setEditingTabs([]);
        setIsMobileFirstStep(true);
        setAssetFinishedMap({});
        setDebtFinishedMap({});
        setEmailsSent(new Set());
        setCurrentAboutYouStepKey(null);
      },
      onError: () => {
        showNotification("Failed to connect to server", "error");
      },
    });
  }, [formik, deleteDraftQuickLoan, showNotification]);

  const completedSteps = useMemo(() => {
    return formik.values.completedSteps;
  }, [formik.values.completedSteps]);

  const displayedTabs = tabs.filter((tab) => !completedSteps.includes(tab.key));
  displayedTabs.unshift({
    key: "completed-step",
    title: "Completed",
  });

  const handleChangeTab = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const {
    mutate: updateDraftQuickLoanMutation,
    isLoading: isUpdatingDraftQuickLoan,
  } = useMutation(
    ({ scenarioId, params }) => updateDraftQuickLoan(scenarioId, params),
    {
      enabled: !!formik.values.scenarioId,
      onError: () => {
        showNotification("Failed to sync data to server", "error");
      },
    }
  );

  const onFinishStep = async (step) => {
    const currentStepIndex = displayedTabs.findIndex((tab) => tab.key === step);
    const completedStepSet = new Set(completedSteps);
    completedStepSet.add(step);
    setSelectedTab(displayedTabs[currentStepIndex + 1].key);
    const completed = [...completedStepSet];
    await formik.setFieldValue("completedSteps", completed);
    setEditingTabs(editingTabs.filter((tab) => tab !== step));
  };

  const onEditStep = async (step) => {
    const completedStepSet = new Set(completedSteps);
    completedStepSet.delete(step);
    setSelectedTab(step);
    const completed = [...completedStepSet];
    await formik.setFieldValue("completedSteps", completed);
    setEditingTabs([...editingTabs, step]);
  };

  const syncTimeoutRef = useRef(null);
  // update the loan states of loan application
  useEffect(() => {
    formik.setFieldValue("loanStates", {
      selectedTab,
      editingTabs,
      isMobileFirstStep,
      assetFinishedMap,
      debtFinishedMap,
      emailsSent: [...emailsSent],
      currentAboutYouStepKey,
    });
  }, [
    selectedTab,
    editingTabs,
    isMobileFirstStep,
    assetFinishedMap,
    debtFinishedMap,
    emailsSent,
    currentAboutYouStepKey,
  ]);

  // actually sync the loan to Connective
  useEffect(() => {
    if (formik.values.scenarioId) {
      // remove the debounce to avoid updating old data
      if (syncTimeoutRef.current) {
        clearTimeout(syncTimeoutRef.current);
      }
      updateDraftQuickLoanMutation({
        scenarioId: formik.values.scenarioId,
        params: mapFormValueToParams(formik.values),
      });
    }
  }, [formik.values.completedSteps]);

  // sync loan states to backend with 2 seconds debounce
  const useSyncQuickLoanStates = useMutation(({ scenarioId, params }) =>
    syncQuickLoanStates(scenarioId, params)
  );
  useEffect(() => {
    if (syncTimeoutRef.current) {
      clearTimeout(syncTimeoutRef.current);
    }

    syncTimeoutRef.current = setTimeout(() => {
      if (formik.values.scenarioId && !isUpdatingDraftQuickLoan) {
        useSyncQuickLoanStates.mutate({
          scenarioId: formik.values.scenarioId,
          params: {
            ...formik.values.loanStates,
            emailsSent: [...emailsSent],
            rawValues: formik.values,
          },
        });
      }
    }, 2000);
    return () => {
      if (syncTimeoutRef.current) {
        clearTimeout(syncTimeoutRef.current);
      }
    };
  }, [formik.values]);

  const setEditing = (key, isEditing) => {
    if (isEditing) {
      if (!editingTabs.includes(key)) {
        setEditingTabs([...editingTabs, key]);
      }
    } else {
      setEditingTabs(editingTabs.filter((tab) => tab !== key));
    }
  };

  const handleAssetFinished = (assetType, isFinished) => {
    setAssetFinishedMap((prev) => ({
      ...prev,
      [assetType]: isFinished,
    }));
  };

  const handleDebtFinished = (debtType, isFinished) => {
    setDebtFinishedMap((prev) => ({
      ...prev,
      [debtType]: isFinished,
    }));
  };

  const handleEmailSent = (email) => {
    setEmailsSent((prev) => {
      const newEmailsSent = new Set(prev);
      newEmailsSent.add(email);
      return newEmailsSent;
    });
  };

  const afterLoanSubmit = async () => {
    window.scrollTo(0, 0);
    setIsSubmitSuccessDialogOpen(true);
    resetQuickLoan();
  };

  if (isFetchingMyQuickLoan) {
    return <LoadingBackdrop />;
  }

  if (isMobile && isMobileFirstStep) {
    return (
      <Box classes={{ root: classes.mobileFirstStep }}>
        <Box classes={{ root: classes.mobileFirstStepTextContainer }}>
          <Typography
            classes={{
              root: cx(commonClasses.fontSize24, commonClasses.fontWeightBold),
            }}
          >
            Quick Online Application, <br /> That Get&apos;s You Answers Fast!
          </Typography>
          <Typography classes={{ root: cx(commonClasses.fontSize18) }}>
            (This does not effect your credit score)
          </Typography>
        </Box>
        <Button
          classes={{
            root: cx(
              commonClasses.fontSize24,
              commonClasses.fontWeightBold,
              commonClasses.textNoTransform,
              classes.mobileFirstStepButton
            ),
            endIcon: commonClasses.textPrimary,
          }}
          variant="outlined"
          onClick={() => setIsMobileFirstStep(false)}
          endIcon={<NavigateNextIcon />}
        >
          Start Application
        </Button>
      </Box>
    );
  }

  return (
    <LoanApplicationContext.Provider value={{ formik }}>
      <SnackbarProvider maxSnack={3}>
        <Container
          maxWidth={false}
          classes={{ root: commonClasses.mobileNoPadding }}
        >
          <Box display="flex" justifyContent="flex-end" mb={2}>
            <Button
              dense
              customColor="danger"
              variant="outlined"
              endIcon={<ReplayIcon />}
              onClick={() => resetQuickLoan()}
            >
              Reset
            </Button>
          </Box>
          <TabContext value={selectedTab}>
            <TabList
              onChange={handleChangeTab}
              TabIndicatorProps={{ children: <span /> }}
              variant="scrollable"
              classes={{
                root: classes.tabsRoot,
                indicator: classes.indicator,
              }}
            >
              {displayedTabs.map((tabItem) => {
                if (tabItem.key === "completed-step") {
                  return (
                    <Tab
                      hidden={completedSteps.length === 0}
                      disableRipple
                      key={tabItem.title}
                      fullWidth={false}
                      label={tabItem.title}
                      value={tabItem.key}
                      classes={{ root: classes.tabRoot }}
                    />
                  );
                }
                return (
                  <Tab
                    disableRipple
                    key={tabItem.title}
                    fullWidth={false}
                    label={tabItem.title}
                    value={tabItem.key}
                    classes={{ root: classes.tabRoot }}
                  />
                );
              })}
            </TabList>
            {displayedTabs.map((tabItem) => {
              if (tabItem.key === "completed-step") {
                if (completedSteps.length > 0) {
                  return (
                    <TabPanel
                      key={tabItem.key}
                      value={tabItem.key}
                      index={tabItem.key}
                      classes={{ root: classes.tabPanelRoot }}
                    >
                      <CompletedStep
                        completedItems={completedSteps}
                        onEditStep={onEditStep}
                      />
                    </TabPanel>
                  );
                }
              }
              return (
                <TabPanel
                  key={tabItem.key}
                  value={tabItem.key}
                  index={tabItem.key}
                  classes={{ root: classes.tabPanelRoot }}
                >
                  {tabItem.component ? (
                    <tabItem.component
                      formik={formik}
                      onFinishStep={onFinishStep}
                      tabKey={tabItem.key}
                      isEditing={editingTabs.includes(tabItem.key)}
                      setEditing={(isEditing) =>
                        setEditing(tabItem.key, isEditing)
                      }
                      assetFinishedMap={assetFinishedMap}
                      onAssetFinished={handleAssetFinished}
                      debtFinishedMap={debtFinishedMap}
                      onDebtFinished={handleDebtFinished}
                      emailsSent={emailsSent}
                      onEmailSent={handleEmailSent}
                      currentAboutYouStepKey={currentAboutYouStepKey}
                      setCurrentAboutYouStepKey={setCurrentAboutYouStepKey}
                      afterLoanSubmit={afterLoanSubmit}
                    />
                  ) : (
                    <div>This feature is currently in development</div>
                  )}
                </TabPanel>
              );
            })}
          </TabContext>
        </Container>
        <CustomModal
          isOpen={isSubmitSuccessDialogOpen}
          handleCloseModal={() => setIsSubmitSuccessDialogOpen(false)}
        >
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography
                classes={{
                  root: cx(commonClasses.fontWeightBold),
                }}
              >
                Thank you for your submission, your broker will call you
                shortly.
              </Typography>
            </Grid>
            <Grid item xs={12} container justifyContent="flex-end">
              <Button dense onClick={() => setIsSubmitSuccessDialogOpen(false)}>
                OK
              </Button>
            </Grid>
          </Grid>
        </CustomModal>
      </SnackbarProvider>
    </LoanApplicationContext.Provider>
  );
};

export default LoanApplication;
